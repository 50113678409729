import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
	selector: "cp-base-card",
	templateUrl: "./base-card.component.html",
	styleUrl: "./base-card.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseCardComponent {
	@Input() customClass?: string;
}
