import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";

import { BaseCardComponent } from "./base-card.component";

@NgModule({
	imports: [CommonModule, MatCardModule],
	declarations: [BaseCardComponent],
	exports: [BaseCardComponent],
})
export class BaseCardModule {}
